/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";

:root {
    --form-width: 450px;
    --red-shadow: rgba(255,135,128,0.5);
    --circle-radius: 150px;
    --img-logo: url("assets/img/login/logo-FTCloud.svg");
    --img-logo-write: url("assets/img/logo-FTCloud-white.svg");
    --img-eua: url("assets/img/login/bandeira-eua.webp");
    --img-br: url("assets/img/login/bandeira-brasil.webp");
    --img-login: url("assets/img/login/login.png");

    --img-background: url("assets/img/background.png");
    --img-banner: url("assets/img/dashboard/banner.png");
    --img-banner-mobile: url("assets/img/dashboard/banner-mobile.png");
    --img-banner-tablet: url("assets/img/dashboard/banner-tablet.png");
    --img-modal-welcome: url("assets/img/welcome/welcome.png");
    --img-modal-welcome-identified1: url("assets/img/vcu/vcu-identified1.png");
    --img-modal-welcome-identified2: url("assets/img/vcu/vcu-identified2.png");
    --img-modal-vcu-created: url("assets/img/vcu/vcu-created.png");
    --img-default-project: url("assets/img/project/default-project.png");
    --img-FT700: url("assets/img/ft/FT700.png");
    --img-FT700-plus: url("assets/img/ft/FT700PLUS.png");
    --img-FT450: url("assets/img/ft/FT450.png");

    --img-nanopro: url("assets/img/ft/nanopro.png");
    --img-audi: url("assets/img/project/audi.png");
    --img-voyage: url("assets/img/project/voyage.png");
    --modal-border-radius: 20px;
    --margin-top-input: 30px;
    --margin-bottom-input: 30px;
    --primary-color: rgb(238, 49, 36); /* #EE3124 */
    --gray10-color: rgb(71, 76, 85);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  width: 100%;
  height: 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.header a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 170px;
  height: 40px;
}

.header strong {
  font-size: 26px;
  color: white;
  font-weight: bold;
  text-align: center;
  flex-grow: 1; 
  margin: 0;
}

.others {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 10px;
}

.others a {
  text-decoration: none;
  color: cornflowerblue;
  font-size: 15px;
}

.others p-button {
  border-radius: 5px;
}


.logo-write {
    background-image: var(--img-logo-write);
    background-size: 100% 100%;
    background-position: center;
    width: 170px;
    height: 40px;
    margin-left: 30px;
}

.logo-red {
    display: inline-flex;
    background-image: var(--img-logo);
    background-size: 100% 100%;
    background-position: center;
    width: 300px;
    height: 100px;
}


@media only screen and (max-width: 768px) {
  .header {
    flex-wrap: wrap;
    height: auto; 
    justify-content: center; 
    padding: 10px 15px; 
  }

  .header strong {
    margin: 0 auto;
  }

  .others {
    justify-content: center; 
    margin-top: 10px; 
  }
}

@media (max-width: 991px) {
  .header a {
    display: none; 
  }

  .header strong {
    text-align: left;
    margin-left: 10px; 
  }
}